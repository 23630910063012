<template>
  <div>

    <default-navigation />

    <page-header title="contactUs.title" />

    <main id="main">

      <!-- ======= Contact Section ======= -->
      <section
        id="our-team"
        class="team section-bg"
      >
        <div
          class="container"
          data-aos="fade-up"
        >
          <div class="d-flex flex-row justify-content-center text-center">
            <div class="row gutter-60 text-center">
              <div
                class="col-md-4 row d-none"
                data-aos="fade-up"
                data-aos-delay="0"
              >
                <div class="col-6 mb-4 w-50 mx-auto">
                  <img
                    src="@/assets/images/art/ui-gps.svg"
                    alt="Maps"
                  ></div>
                <h5 class="modalmu-blue">
                  {{ $t('contactUs.ourEmail') }}
                </h5>
                <p class="mb-0">
                  Jl Terusan Jakarta<br class="d-none d-md-block"> Ruko Puridago Kav 428<br> Antapani Tengah - Bandung
                </p>
              </div>
              <!--/column -->
              <div
                class="col-md-4 d-none"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <div class="col-6 mb-4 w-50 mx-auto">
                  <img
                    src="@/assets/images/art/co-telephone.svg"
                    alt="Telephone"
                  ></div>
                <h5 class="modalmu-blue">
                  Our Telephone
                </h5>
                <p class="mb-0">
                  ( +62 ) 22 - 2050 - 2299
                </p>
              </div>
              <!--/column -->
              <div
                class="col-md-12"
                data-aos="fade-up"
                data-aos-delay="600"
              >
                <div class="col-6 mb-4 w-50 mx-auto">
                  <img
                    src="@/assets/images/art/co-email.svg"
                    alt="Email"
                  >
                </div>
                <p class="mb-0">
                  <a
                    class="nocolor"
                    href="mailto:admin@modalmu.com"
                  >admin@modalmu.com</a>
                </p>
              </div>
              <!--/column -->
            </div>
          </div>
        </div>
      </section><!-- End Contact Section -->

    </main><!-- End #main -->

    <footer-default />

    <a
      href="#"
      class="back-to-top"
    ><i class="ri-arrow-up-line" /></a>
  </div>
</template>

<script>
import DefaultNavigation from '@/components/navigation/DefaultNavigation.vue'
import PageHeader from '@/components/header/PageHeader.vue'
import FooterDefault from '@/components/footer/FooterDefault.vue'

export default {
  metaInfo: {
    title: 'Hubungi Kami',
    meta: [
      { charset: 'utf-8' },
      { name: 'description', content: 'Punya pertanyaan? atau ingin mengenal kami lebih jauh, temui kami disini.' },
      { name: 'og:title', content: 'Hubungi Kami' },
      { name: 'og:description', content: 'Punya pertanyaan? atau ingin mengenal kami lebih jauh, temui kami disini.' },
    ],
  },
  components: {
    DefaultNavigation,
    PageHeader,
    FooterDefault,
  },
  data() {
    return {
    }
  },
  computed: {
  },
}
</script>

<style lang="scss" scope>

@media (max-width: 768px) {
  #main {
    margin-top: 0;
  }
}
</style>
